import type { AlignItemsProperties, JustifyContentProperties } from "./types";

import { styled } from "@mui/material";

interface RowFlexProps {
  alignItems?: AlignItemsProperties;
  justify?: JustifyContentProperties;
  columnGap?: number;
}

export const Row = styled("div")<RowFlexProps>(({ alignItems, justify, columnGap }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: justify ?? "flex-start",
  alignItems: alignItems ?? "flex-start",
  columnGap: columnGap === undefined ? "0" : `${String(columnGap)}px`,
}));
