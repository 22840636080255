import { isDefined } from "~utils/boolean-utils";

import { Row } from "../../flexs/Row";

import { StyledTextFieled } from "./RoundTextField.style";

import type { TextFieldProps } from "@mui/material";
import type { FC } from "react";

type RoundTextFieldProps = TextFieldProps & {
  maxLength?: number;
};

const RoundTextField: FC<RoundTextFieldProps> = ({ maxLength, error, helperText, ...props }) => {
  const { value } = props;
  const valueLength = String(value ?? "").length;

  const isError = (error ?? false) || (isDefined(maxLength) && valueLength > maxLength);

  return (
    <StyledTextFieled
      {...props}
      error={isError}
      helperText={
        <Row justify="space-between">
          <div>{helperText}</div>
          {isDefined(maxLength) && <div>{`${valueLength}/${maxLength}`}</div>}
        </Row>
      }
    />
  );
};

export default RoundTextField;
