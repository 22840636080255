import theme from "~providers/muiThemeProvider/mui-theme";

import type { ButtonProps } from "../types";

import Sc from "./PrimaryButton.style";

import type { FC } from "react";

type PrimaryButtonProps = ButtonProps;
const PrimaryButton: FC<PrimaryButtonProps> = ({ value, color, ...props }) => {
  return (
    <Sc.Button variant="contained" {...props} color={color ?? "primary"} disableElevation>
      <Sc.Text value={value} weight="bold" color={theme.palette.common.white} />
    </Sc.Button>
  );
};

export default PrimaryButton;
