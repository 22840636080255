import { Row } from "../flexs/Row";

import { Sc } from "./Loading.style";

import type { FC } from "react";

interface Props {
  size?: "medium" | "small";
}
const Loading: FC<Props> = ({ size = "small" }) => {
  return (
    <Row justify="center">
      <Sc.CircularProgress size={`${size === "medium" ? 50 : 30}px`} />
    </Row>
  );
};

export default Loading;
