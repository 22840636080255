import type { TxKeyPath } from "./types";

import type { TOptions } from "i18next";
import { useTranslation as _useTranslation } from "react-i18next";

export type InterpolationValue = TOptions;
export type UseTranslateReturnType = (id: TxKeyPath, values?: InterpolationValue, defaultMessage?: string) => string;

export const useTranslate = (): UseTranslateReturnType => {
  const { t: _translate } = _useTranslation("translation", { useSuspense: false });
  const translate = (id: TxKeyPath, values?: InterpolationValue, defaultMessage = "") => {
    try {
      return _translate(id, defaultMessage, values);
    } catch (_error: unknown) {
      // eslint-disable-next-line no-console
      console.error(`translation error: no translations provided for ${id}`);
      return id;
    }
  };
  return translate;
};
