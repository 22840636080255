import { Body1 } from "../../typography/Body1";

import { Button, styled } from "@mui/material";

const Sc = {
  Button: styled(Button)({
    padding: "10px 24px",
    textTransform: "none",
  }),
  Text: styled(Body1)`
    cursor: pointer !important;
  `,
};

export default Sc;
