import type { TWeight } from "./types";

export const mapTWeitghToFontWeight = (weight?: TWeight): number => {
  if (weight === "regular" || weight === undefined) {
    return 400;
  }
  if (weight === "medium") {
    return 600;
  }
  if (weight === "bold") {
    return 700;
  }

  return 800;
};
