import type { ReactElement } from "react";

export default function Logo(): ReactElement {
  return (
    <svg width="123" height="33" viewBox="0 0 123 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M100.522 5.70091L91.027 32.801H96.9159L98.7998 27.2644H108.617L110.299 32.801H116.349L106.974 5.70091H100.522ZM100.242 22.3174L103.648 11.693L103.687 12.0141L107.013 22.6388L100.242 22.3174Z"
        fill="#00C3C9"
      />
      <path d="M123 5.70091H117.198V32.801H123V5.70091Z" fill="#00C3C9" />
      <path
        d="M11.0828 12.4171C8.43855 12.4171 6.09198 13.7445 5.22169 15.5173V5.70097H0V32.8011H5.22169V21.3934C5.22169 18.7355 6.72873 16.5629 9.33378 16.5629C11.6975 16.5629 12.7641 17.4872 12.7641 20.4662V32.8011H17.9858V18.7355C17.9858 14.7935 15.1299 12.4171 11.0828 12.4171Z"
        fill="#184B66"
      />
      <path
        d="M54.1481 21.961C52.7014 21.3884 50.6858 20.9682 48.5539 20.3583C46.5369 19.7825 45.51 19.325 45.51 18.2168C45.51 17.1089 46.5758 16.633 48.4393 16.633C50.6858 16.633 51.6742 17.3566 51.9405 19.105H56.6966C56.43 15.0254 53.9949 12.9264 48.5928 12.9264C42.8843 12.9264 40.7153 15.3427 40.7153 18.5143C40.7153 20.1951 41.3242 21.6521 43.0746 22.76C44.4067 23.6004 46.1575 24.1377 49.3148 24.9029C50.9136 25.2858 52.3217 25.7075 52.3217 27.0456C52.3217 28.4586 50.7618 28.9406 49.0108 28.9406C46.6129 28.9406 45.1288 27.8472 45.1288 26.0988H40.0304C40.1064 29.887 43.3024 32.6883 49.0482 32.6883C54.3758 32.6883 57.2295 30.3842 57.2295 26.4844C57.2295 24.0381 56.1265 22.7641 54.1481 21.961Z"
        fill="#184B66"
      />
      <path
        d="M63.2405 7.4493H58.3089V25.2963C58.3089 29.5633 60.5569 32.7422 66.3265 32.7422C71.8958 32.7422 74.2641 29.5207 74.2641 25.3357V19.6881H69.3325V24.854C69.3325 27.3084 68.3488 28.7179 66.2662 28.7179C64.1421 28.7179 63.2405 27.2694 63.2405 24.7721V16.7741H74.2641V12.9859H63.2405V7.4493Z"
        fill="#184B66"
      />
      <path d="M80.3561 5.70091H75.1344V10.6547H80.3561V5.70091Z" fill="#184B66" />
      <path d="M80.3561 12.9859H75.1344V32.801H80.3561V12.9859Z" fill="#184B66" />
      <path
        d="M89.463 12.9859C89.463 10.6547 89.9292 9.92328 92.8301 9.92328V5.69596C87.0282 5.69596 84.3341 8.0324 84.3341 12.9862H81.5165V16.7744H84.4174V32.8013H89.349V16.7744H92.8301V12.9862L89.463 12.9859Z"
        fill="#184B66"
      />
      <path
        d="M29.2995 13.5506V13.1392C30.4599 12.9119 31.4935 11.7789 31.4935 10.423C31.4935 8.89785 30.2238 7.65853 28.7057 7.65853C27.1873 7.65853 25.9161 8.89785 25.9161 10.423C25.9161 11.7786 26.9788 12.9119 28.1391 13.1392V13.5844C22.9174 13.9332 19.4363 17.5011 19.1462 22.8416V32.801H38.8726V22.8416C38.5825 17.2112 34.8113 13.5943 29.2995 13.5506ZM35.1043 21.6944C34.9018 21.7328 34.7379 21.7474 34.5499 21.6894C34.2607 21.6165 34.0582 21.428 33.9282 21.1471C33.5665 20.1109 32.8822 19.2976 31.875 18.7069C31.4459 18.4552 31.2869 17.9079 31.5425 17.4769C31.7929 17.0509 32.3713 16.8912 32.7954 17.143C34.1403 17.9417 35.1139 19.0604 35.6151 20.5078C35.7889 20.9583 35.5528 21.5201 35.1043 21.6944Z"
        fill="#184B66"
      />
      <path
        d="M28.7193 0C28.0924 0 27.5589 0.515194 27.5589 1.14491V4.85616C27.5589 5.48588 28.0924 6.00136 28.7193 6.00136C29.3462 6.00136 29.8797 5.48588 29.8797 4.85616V1.14491C29.8797 0.515194 29.3462 0 28.7193 0Z"
        fill="#00C3C9"
      />
      <path
        d="M37.2742 4.59769C36.9238 4.07551 36.2116 3.93621 35.6917 4.28822L32.6278 6.36241C32.1079 6.71442 31.969 7.4298 32.3194 7.95199C32.6698 8.47418 33.382 8.61346 33.9019 8.26145L36.9658 6.18728C37.4857 5.83556 37.6243 5.12017 37.2742 4.59769Z"
        fill="#00C3C9"
      />
      <path
        d="M24.7102 6.3627L21.6462 4.28852C21.1264 3.93651 20.4145 4.0758 20.0641 4.59798C19.7136 5.12017 19.8523 5.83585 20.3721 6.18757L23.4361 8.26204C23.956 8.61405 24.6682 8.47476 25.0186 7.95257C25.3687 7.4298 25.2301 6.71442 24.7102 6.3627Z"
        fill="#00C3C9"
      />
    </svg>
  );
}
