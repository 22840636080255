import type { AlignItemsProperties, JustifyContentProperties } from "./types";

import { styled } from "@mui/material";

interface ColumnProps {
  justify?: JustifyContentProperties;
  alignItems?: AlignItemsProperties;
  rowGap?: number;
}

export const Column = styled("div")((columnProps: ColumnProps) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: columnProps.justify ?? "auto",
  alignItems: columnProps.alignItems ?? "auto",
  rowGap: columnProps.rowGap === undefined ? "normal" : `${String(columnProps.rowGap)}px`,
}));
