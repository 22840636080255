export enum Paths {
  Login = "/login",
  Tasks = "/tasks",
  Reporting = "/reporting",
  Teams = "/teams",
  Employees = "/employees",
  GeneralInfos = "/general_infos",
  Services = "/services",
  Settings = "/settings",
  Forbidden = "/forbidden",
  Create = "/create",
  CreateIncident = "/create/incident",
  CreateGuestNeed = "/create/guestNeed",
  Guests = "/guests",
  GuestNeeds = "/guestNeeds",
  GuestStays = "/stays",
  GuestConversations = "/conversations",
  CreateGuest = "/guests/create",
  ImportGuests = "/guests/import",
  UpdatePassword = "/updatePassword",
  ForgotPassword = "/forgotPassword",
  CreateEmployee = "/employees/create",
  ImportEmployees = "/employees/import",
  CreateService = "/services/create",
  TeamOperations = "/teamOperations",
  CreateOperation = "/teamOperations/create",
  Notifications = "/notifications",
  Faqs = "/faqs",
  Zones = "/zones",
  Categories = "/categories",
  Operations = "/operations",
  NewTask = "/new_task",
  NotFound = "/not-found",
}
